import React from 'react'
import Section from './Section'
import Section1 from './Section1'
import Navbar3 from '../../layout/Navbar3'

const Index11 = () => {
  return (
    <React.Fragment>
        <Navbar3 />
        <Section />
        <Section1 />
    </React.Fragment>
  )
}

export default Index11