import React from 'react'
import AgencyInetriorcontact from '../../components/AgencyInetriorcontact';

const Index = () => {
  return (
    <React.Fragment>
        <AgencyInetriorcontact /> 
    </React.Fragment>
  )
}

export default Index