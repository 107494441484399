import React from 'react'
import Section from './Section'


const Index3 = () => {
  return (
    <React.Fragment>
        <Section />
    </React.Fragment>
  )
}

export default Index3
